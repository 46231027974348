import * as React from "react"

// Styles
import "./_svg-text.scss"

const SvgText = () => {
  return (
    <div className='svg-text__wrapper  svg-text__convincing'>
      <svg
        width='360'
        height='66'
        viewBox='0 0 360 66'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='svg-text'
        id='svg-convincing'
      >
        <path
          d='M18.2505 50.7604C25.1635 50.7604 30.4865 46.82 32.7678 40.46L24.334 37.1418C23.5735 40.46 21.154 42.5339 18.1123 42.5339C12.7893 42.5339 10.9919 37.9022 10.9919 33.0631C10.9919 28.1549 12.8584 23.3158 18.1814 23.3158C21.2231 23.3158 23.5735 25.5279 24.2648 28.9153L33.1135 25.3897C30.5557 18.4767 25.1635 15.1584 18.1814 15.1584C6.77495 15.1584 1.38281 24.0762 1.38281 33.0631C1.38281 41.9117 6.70582 50.7604 18.2505 50.7604Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M52.0135 50.7604C62.7287 50.7604 69.296 42.3956 69.296 32.9248C69.296 23.3849 62.7978 15.1584 52.0135 15.1584C41.1601 15.1584 34.731 23.3849 34.731 32.9248C34.731 42.3956 41.2292 50.7604 52.0135 50.7604ZM52.0135 42.5339C45.9992 42.5339 44.0636 37.7639 44.0636 32.9248C44.0636 27.9475 46.0683 23.3158 52.0135 23.3158C58.0278 23.3158 59.9635 27.9475 59.9635 32.9248C59.9635 37.8331 57.9587 42.5339 52.0135 42.5339Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M94.327 15.1584C90.4557 15.1584 86.861 16.2645 84.3723 19.4445V15.9189H74.9706V49.9999H84.3723V35.2061C84.3723 30.2979 84.3723 23.3158 91.4236 23.3158C97.2996 23.3158 97.2996 28.1549 97.2996 30.6435V49.9999H106.632V27.671C106.632 19.8593 102.139 15.1584 94.327 15.1584Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M122.702 49.9999H130.375L144.27 15.9189H134.177L126.504 37.7639L119.038 15.9189H108.807L122.702 49.9999Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M147.675 10.3193H157.354V1.81634H147.675V10.3193ZM147.814 49.9999H157.215V15.9189H147.814V49.9999Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M185.263 15.1584C181.391 15.1584 177.797 16.2645 175.308 19.4445V15.9189H165.906V49.9999H175.308V35.2061C175.308 30.2979 175.308 23.3158 182.359 23.3158C188.235 23.3158 188.235 28.1549 188.235 30.6435V49.9999H197.568V27.671C197.568 19.8593 193.074 15.1584 185.263 15.1584Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M219.43 50.7604C226.343 50.7604 231.666 46.82 233.947 40.46L225.513 37.1418C224.753 40.46 222.333 42.5339 219.291 42.5339C213.968 42.5339 212.171 37.9022 212.171 33.0631C212.171 28.1549 214.037 23.3158 219.36 23.3158C222.402 23.3158 224.753 25.5279 225.444 28.9153L234.293 25.3897C231.735 18.4767 226.343 15.1584 219.36 15.1584C207.954 15.1584 202.562 24.0762 202.562 33.0631C202.562 41.9117 207.885 50.7604 219.43 50.7604Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M239.151 10.3193H248.829V1.81634H239.151V10.3193ZM239.289 49.9999H248.691V15.9189H239.289V49.9999Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M276.738 15.1584C272.867 15.1584 269.272 16.2645 266.784 19.4445V15.9189H257.382V49.9999H266.784V35.2061C266.784 30.2979 266.784 23.3158 273.835 23.3158C279.711 23.3158 279.711 28.1549 279.711 30.6435V49.9999H289.044V27.671C289.044 19.8593 284.55 15.1584 276.738 15.1584Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M310.421 44.4695C306.135 43.9856 303.025 43.4326 303.025 41.1513C303.025 40.5983 303.232 40.0452 303.578 39.6304C305.513 40.46 307.725 40.8748 310.145 40.8748C318.164 40.8748 324.247 35.9666 324.247 28.3623C324.247 26.2192 323.764 24.2836 322.934 22.5553L327.911 18.4767L323.003 12.6006L317.68 17.1632C315.399 15.8497 312.703 15.1584 309.868 15.1584C302.126 15.1584 295.559 20.3432 295.559 28.0166C295.559 30.9892 296.526 33.6853 298.255 35.7592C296.388 37.5565 295.282 39.6996 295.282 42.6722C295.282 46.3361 297.01 48.6173 299.499 49.9999L294.522 52.4886C294.383 53.0417 294.314 53.6638 294.314 54.3551C294.314 61.199 300.605 65.2085 310.629 65.2085C319.892 65.2085 326.598 61.8212 326.598 54.3551C326.598 46.4743 318.786 45.5065 310.421 44.4695ZM310.007 22.9701C313.118 22.9701 314.915 24.9749 314.915 28.0857C314.915 31.0583 313.394 33.4087 310.007 33.4087C306.481 33.4087 304.96 30.9892 304.96 28.0857C304.96 25.044 306.827 22.9701 310.007 22.9701ZM311.044 58.2955C306.896 58.2955 303.647 57.2586 303.647 54.9082C303.647 54.7699 303.647 54.5625 303.716 54.4243L306.204 52.0047C307.034 52.143 307.794 52.2812 308.624 52.3504C312.979 52.9034 317.265 53.1108 317.265 55.5995C317.265 57.3277 314.915 58.2955 311.044 58.2955Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
        <path
          d='M339.794 35.2753H347.744V32.3027C347.744 25.7353 359.15 26.9105 359.15 14.5363C359.15 5.27284 352.03 1.05591 343.941 1.05591C334.609 1.05591 328.318 6.72456 328.318 16.6102H336.544C336.544 11.5637 338.826 8.8676 344.011 8.8676C348.227 8.8676 350.993 10.5958 350.993 14.398C350.993 21.7258 339.794 19.9975 339.794 31.8879V35.2753ZM338.964 49.9999H348.85V40.5983H338.964V49.9999Z'
          stroke='rgba(250, 250, 250, 1)'
          className='svg-convincing__draw'
        />
      </svg>
    </div>
  )
}

export default SvgText

import * as React from "react"
import { useContext, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactGA from "react-ga"

// Context
import CursorContext from "../../context/cursorContext"

// Styles
import "./_home-journal-gallery.scss"

// Components
import { Section, FlexRow, JournalGalleryItems, JournalGalleryItem } from "../StyledComponents/layoutComponentStyles"
import WrapButton from "../Buttons/wrapButton"

// Animations
import { motion } from "framer-motion"
import { pageTransitionInstant } from "../../animations/globalVariants"

interface HomeJournalGalleryProps {}

/*








 
*/

const HomeJournalGallery: React.FunctionComponent<HomeJournalGalleryProps> = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityPosts(sort: { fields: order, order: ASC }, limit: 4) {
        edges {
          node {
            postCategories {
              title
            }
            mainImage {
              altText
              image {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            publishedAt(formatString: "DD MMM")
            slug {
              current
            }
            title
            excerpt
            author {
              name
            }
            timeToRead
          }
        }
      }
    }
  `)

  // Context
  const { cursorState, setCursorState } = useContext(CursorContext)

  // States
  const [prevCursor, setPrevCursor] = useState<any>(null)

  return (
    <Section>
      <motion.div initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} className='journal-gallery'>
        <h3>Journal</h3>
        <JournalGalleryItems>
          {data.allSanityPosts.edges.map(({ node: post }: any) => (
            <JournalGalleryItem featuredImage={post.mainImage.image.asset.fluid.src} key={post.slug.current}>
              <Link
                to={`/${post.slug.current}/`}
                className='journal-gallery__link'
                title={post.title}
                onMouseEnter={() => setCursorState?.("is-post")}
                onMouseLeave={() => setCursorState?.("")}
              ></Link>

              <div className='c-journal-gallery-item__content'>
                <p className='o-par-sml'>
                  {post.publishedAt}
                  <span>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  {post.timeToRead} min read
                </p>
                <Link
                  to={`/${post.slug.current}/`}
                  title={post.title}
                  onMouseEnter={() => {
                    setPrevCursor(cursorState)
                    setCursorState?.("is-link")
                  }}
                  onMouseLeave={() => setCursorState?.(`${prevCursor}`)}
                >
                  <h5>{post.title}</h5>
                </Link>
              </div>
              <Link
                to={`/${post.slug.current}/`}
                className='journal-gallery__button'
                title={post.title}
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <p>Read more</p>
                <div>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 73.62'>
                    <line
                      y1='36.81'
                      x2='112.87'
                      y2='36.81'
                      fill='none'
                      stroke='rgba(250, 250, 250, 1)'
                      strokeMiterlimit='10'
                      strokeWidth='4.5'
                    />
                    <path
                      d='M76.58,0a36.81,36.81,0,0,0,36.81,36.81A36.81,36.81,0,0,0,76.58,73.62'
                      fill='none'
                      stroke='rgba(250, 250, 250, 1)'
                      strokeMiterlimit='10'
                      strokeWidth='4.5'
                    />
                  </svg>
                </div>
              </Link>
            </JournalGalleryItem>
          ))}
        </JournalGalleryItems>
        <FlexRow>
          <WrapButton
            text={"More posts"}
            link={"/journal/"}
            centered={true}
            clickAction={() =>
              ReactGA.event({ category: "Navigation", action: "Clicked more posts button", label: "More Posts Button" })
            }
          />
        </FlexRow>
      </motion.div>
    </Section>
  )
}

export default HomeJournalGallery

import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"

// Components
import { Link } from "gatsby"
import Image from "gatsby-image"

interface CaseStudiesGalleryItemProps {
  project: any
}

/*









*/

const CaseStudiesGalleryItem: React.FunctionComponent<CaseStudiesGalleryItemProps> = ({ project }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <div className='o-cases-gallery__item'>
      <Link
        to={`/${project.slug.current}/`}
        style={{ textDecoration: "none" }}
        onMouseEnter={() => setCursorState?.("is-case")}
        onMouseLeave={() => setCursorState?.("")}
        onMouseUp={() => setCursorState?.("")}
      >
        <div className='o-cases-gallery__thumb'>
          <div className='o-cases-gallery-thumb__inner'>
            <Image
              fluid={project.thumbnail.image.asset.fluid}
              alt={project.thumbnail.altText}
              className='o-cases-gallery-thumb__asset'
            />
          </div>
        </div>
      </Link>
      <Link
        to={`/${project.slug.current}/`}
        style={{ textDecoration: "none" }}
        title={project.title}
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
        onClick={() => setCursorState?.("")}
      >
        <h4>{project.title}</h4>
      </Link>
      <p className='o-par-lrg'>{project.tagline}</p>
    </div>
  )
}

export default CaseStudiesGalleryItem

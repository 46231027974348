import * as React from "react"
import { useEffect } from "react"

// Styles
import "./_home-dynamic-type.scss"

// Components
import { Section } from "../StyledComponents/layoutComponentStyles"

// Animations
import { gsap } from "gsap"
import { motion } from "framer-motion"
import { pageTransitionInstant } from "../../animations/globalVariants"

interface HomeDynamicTypeProps {}

/*






 


*/

const HomeDynamicType: React.FunctionComponent<HomeDynamicTypeProps> = () => {
  // Effects
  useEffect(() => {
    gsap.to("#rail-1", {
      scrollTrigger: {
        trigger: "#rail-1",
        scrub: 2.5,
      },
      x: "-5%",
      ease: "linear",
    })

    gsap.to("#rail-2", {
      scrollTrigger: {
        trigger: "#rail-4",
        scrub: 1.5,
      },
      x: "5%",
      ease: "linear",
    })

    gsap.to("#rail-3", {
      scrollTrigger: {
        trigger: "#rail-3",
        scrub: 1,
      },
      x: "-5%",
      ease: "linear",
    })

    gsap.to("#rail-4", {
      scrollTrigger: {
        trigger: "#rail-4",
        scrub: 1.5,
      },
      x: "5%",
      ease: "linear",
    })
  }, [])

  return (
    <Section>
      <motion.div initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} className='dynamic-type'>
        <div className='dt-rail' id='rail-1'>
          <h1 className='o-type-xxl'>&nbsp;Design&nbsp;</h1>
          <h1 className='o-type-xxl'>
            <span className='o-outline-type'>&nbsp;Web Development&nbsp;</span>&nbsp;Branding&nbsp;
          </h1>
        </div>
        <div className='dt-rail' id='rail-2'>
          <h1 className='o-type-xxl'>
            <span className='o-outline-type'>Corporate Identity</span>
          </h1>
          <h1 className='o-type-xxl'>
            &nbsp;&nbsp;e-Commerce&nbsp;&nbsp;
            <span className='o-outline-type'>Print Collateral</span>
          </h1>
        </div>
        <div className='dt-rail' id='rail-3'>
          React Development
          <h1 className='o-type-xxl'>&nbsp;Brand Strategy&nbsp;</h1>
          <h1 className='o-type-xxl'>
            <span className='o-outline-type'>&nbsp;UX Design&nbsp;</span>&nbsp;React Development
          </h1>
        </div>
        <div className='dt-rail' id='rail-4'>
          <h1 className='o-type-xxl'>
            <span className='o-outline-type'>Design Systems</span>&nbsp;&nbsp;Digital Experience&nbsp;&nbsp;
          </h1>
          <h1 className='o-type-xxl'>
            <span className='o-outline-type'>Logo Creation&nbsp;&nbsp;</span>
          </h1>
        </div>
      </motion.div>
    </Section>
  )
}

export default HomeDynamicType

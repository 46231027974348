import * as React from "react"
import { useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Components
import SEO from "../components/seo"
import HomeHero from "../components/HomeHero"
import FeaturedCase from "../components/HomeFeaturedCase"
import HomeCasesGallery from "../components/HomeCasesGallery"
import DynamicType from "../components/HomeDynamicType"
import JournalGallery from "../components/HomeJournalGallery"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Interfaces
interface IndexPageProps {}

// SEO
const seo = {
  title: "Digital Design Studio",
  description:
    "Silk Studio is a multidisciplinary, freelance digital design studio specialising in web development, UI/UX design and brand identity.",
  keywords: [
    "digital design studio",
    "web design shrewsbury",
    "web design midlands",
    "freelance web developer",
    "brand design uk",
    "freelance brand design",
  ],
}

/*









*/

const IndexPage: React.FunctionComponent<IndexPageProps> = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />
      {scrollSafe && (
        <>
          <HomeHero />
          <FeaturedCase />
          <HomeCasesGallery />
          <DynamicType />
          <JournalGallery />
        </>
      )}
    </>
  )
}

export default IndexPage

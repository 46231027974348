import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactGA from "react-ga"

// Styles
import { $desktop } from "../../utils/breakpoints"

// Components
import { Container, Section } from "../StyledComponents/layoutComponentStyles"
import SvgText from "../SvgText"
import CaseStudiesGalleryItem from "../CaseStudies/CaseStudiesGalleryItem"
import WrapButton from "../Buttons/wrapButton"

// Animations
import { motion } from "framer-motion"
import { galleryScrub, casesCTA } from "../../animations/pages/homeAnim"
import { pageTransitionInstant } from "../../animations/globalVariants"

interface HomeCasesGalleryProps {}

/*







 

*/

const HomeCasesGallery: React.FunctionComponent<HomeCasesGalleryProps> = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProjects(limit: 4, sort: { order: ASC, fields: order }, filter: { featured: { eq: false } }) {
        edges {
          node {
            title
            comingSoon
            slug {
              current
            }
            tagline
            thumbnail {
              altText
              image {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const onComplete = () => {
    const thumbnails = Array.from(document.querySelectorAll(".o-cases-gallery-thumb__asset"))
    thumbnails.forEach(thumbnail => {
      return galleryScrub(thumbnail)
    })

    casesCTA()
  }

  return (
    <Section>
      <motion.div initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} onAnimationComplete={onComplete}>
        <Container fourteenClm={$desktop ? true : false} sixteenClm={!$desktop ? true : false}>
          <div className='o-cases-gallery'>
            {data.allSanityProjects.edges.map(({ node: project }: any) => (
              <CaseStudiesGalleryItem project={project} key={project.slug.current} />
            ))}
          </div>
          <div className='c-more-cases__cta'>
            <h2>
              Still need <SvgText />
            </h2>
            <div id='more-cases-btn'>
              <WrapButton
                text={"See all work"}
                link={"/work/"}
                responsiveCentered={true}
                clickAction={() =>
                  ReactGA.event({ category: "Navigation", action: "Clicked more cases button", label: "More Cases Button" })
                }
              />
            </div>
          </div>
        </Container>
      </motion.div>
    </Section>
  )
}

export default HomeCasesGallery

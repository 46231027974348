import * as React from "react"
import { useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Styles
import "./_home-featured-case.scss"

// Components
import Image from "gatsby-image"
import CursorContext from "../../context/cursorContext"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Section, FlexRow } from "../StyledComponents/layoutComponentStyles"
import { $desktop, $tablet } from "../../utils/breakpoints"

// Animations
import { gsap } from "gsap"
import { motion } from "framer-motion"
import { SplitText } from "gsap/SplitText"
import { featuredCasePtOne, featuredCasePtTwo, galleryScrub } from "../../animations/pages/homeAnim"
import { pageTransitionInstant } from "../../animations/globalVariants"

// Interfaces
interface HomeFeaturedCaseProps {}

/*









*/

const HomeFeaturedCase: React.FunctionComponent<HomeFeaturedCaseProps> = () => {
  const data = useStaticQuery(graphql`
    {
      sanityProjects(featured: { eq: true }) {
        featuredDescription
        heroImage {
          altText
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        featuredImage {
          altText
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        slug {
          current
        }
        title
        publishedAt
        moduleContent {
          ... on SanityProjectHeroInfo {
            _type
            client
            dateCompleted(formatString: "MMM YYYY")
          }
        }
      }
    }
  `)

  // Context
  const { setCursorState } = useContext(CursorContext)

  // Helper Functions
  const headlineScrubScroll = () => {
    if ($desktop) {
      return gsap.from(".headline-link", {
        scrollTrigger: {
          trigger: ".headline-link",
          scrub: 0.25,
        },
        y: "-20%",
        ease: "linear",
      })
    } else if ($tablet) {
      return gsap.from(".featured-case-study__content", {
        scrollTrigger: {
          trigger: ".featured-case-study",
          scrub: 0.25,
        },
        y: "-45%",
        ease: "linear",
      })
    } else {
      return gsap.from(".featured-case-study__content", {
        scrollTrigger: {
          trigger: ".featured-case-study",
          scrub: 0.25,
        },
        y: "-45%",
        ease: "linear",
      })
    }
  }

  const getDate = () => {
    let date: string = ""

    for (let i = 0; i < data.sanityProjects.moduleContent.length; i++) {
      if (data.sanityProjects.moduleContent[i]._type === "projectHeroInfo") {
        date = data.sanityProjects.moduleContent[i].dateCompleted
        return date
      }
    }
    return date
  }

  // Effects
  useEffect(() => {
    const headline = document.querySelector(".headline-link__title")
    const split = new SplitText(headline, { type: "words,chars,lines" })
    const lines = split.lines

    // Run gsap animations
    featuredCasePtOne()
    featuredCasePtTwo(lines)

    // Featured image scrub scroll
    galleryScrub(".featured-case__img")

    // Featured headline scrub scroll
    headlineScrubScroll()
  }, [])

  return (
    <Section>
      <motion.div initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} className='featured-case-study'>
        <FlexRow justifySpaceBetween className='featured-case__meta'>
          <p className='o-accent'>Featured Case Study</p>

          <p key={data.sanityProjects.slug.current} className='o-accent'>
            {getDate()}
          </p>
        </FlexRow>
        <Link
          to={`/${data.sanityProjects.slug.current}/`}
          className='featured-case__img-link'
          onMouseEnter={() => setCursorState?.("is-case")}
          onMouseLeave={() => setCursorState?.("")}
          onClick={() => {
            setCursorState?.("")
            ReactGA.event({
              category: "Navigation",
              action: "Clicked featured case image",
              label: "Featured Case Image",
            })
          }}
        >
          <div className='featured-case__img-wrapper'>
            <FeaturedImage data={data} />
          </div>
        </Link>
        <div className='featured-case-study__content'>
          <Link
            to={`/${data.sanityProjects.slug.current}/`}
            className='headline-link'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
            onClick={() => {
              setCursorState?.("")
              ReactGA.event({
                category: "Navigation",
                action: "Clicked featured case link",
                label: "Featured Case Link",
              })
            }}
          >
            <h1 className='headline-link__title'>{data.sanityProjects.title}</h1>
            <svg viewBox='0 0 67 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                id='featured-link-arrow'
                d='M24.6384 56.6703L38.7143 42.536L40.4129 40.8303H38.0058H1V26.0803H38.0058H40.4125L38.7144 24.3748L24.7277 10.3269L33.6865 1.41394L65.5889 33.4554L33.5999 65.586L24.6384 56.6703Z'
                stroke='rgba(250, 250, 250, 1)'
                strokeWidth='2'
              />
            </svg>
          </Link>
          <p id='featured-case__paragraph' className={$tablet ? "o-par-lrg" : undefined}>
            {data.sanityProjects.featuredDescription}
          </p>
        </div>
      </motion.div>
    </Section>
  )
}

export default HomeFeaturedCase

interface FeaturedImageProps {
  data: any
}

export const FeaturedImage: React.FunctionComponent<FeaturedImageProps> = ({ data }) => {
  if (data.sanityProjects.featuredImage.image) {
    return (
      <Image
        fluid={data.sanityProjects.featuredImage.image.asset.fluid}
        alt={data.sanityProjects.featuredImage.altText}
        className='featured-case__img'
      />
    )
  } else
    return (
      <Image
        fluid={data.sanityProjects.heroImage.image.asset.fluid}
        alt={data.sanityProjects.heroImage.altText}
        className='featured-case__img'
      />
    )
}

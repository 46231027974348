import * as React from "react"
import { useContext } from "react"

// Components
import CursorContext from "../../../context/cursorContext"
import { titleHoverIn, titleHoverOut } from "../../../helpers/homeHeroHelpers"
import { $desktop, $mobile } from "../../../utils/breakpoints"

// Animation
import { slideTitleParent, slideTitleChild } from "../../../animations/globalVariants"
import { motion } from "framer-motion"

interface HomeHeroTitleProps {
  hoverReady: boolean
}

interface TitleLineProps {
  children: React.ReactNode
}

interface TitleKeywordProps {
  children: React.ReactNode
  hoverReady: boolean
}

/*









*/

const HomeHeroTitle: React.FunctionComponent<HomeHeroTitleProps> = ({ hoverReady }) => {
  return <>{!$mobile ? <HomeHeroTitleDesktopTablet hoverReady={hoverReady} /> : <HomeHeroTitleMobile hoverReady={hoverReady} />}</>
}

export default HomeHeroTitle

export const HomeHeroTitleDesktopTablet: React.FunctionComponent<HomeHeroTitleProps> = ({ hoverReady }) => {
  return (
    <div className='o-hero-title-wrapper -responsive'>
      <motion.div
        variants={slideTitleParent}
        key='title-line-parent'
        initial='initial'
        animate='animate'
        exit='exit'
        className='o-hero-title -home'
      >
        <TitleLine>We are a digital design studio creating</TitleLine>
        <TitleLine>
          <TitleKeyword hoverReady={hoverReady}>original</TitleKeyword>
          ,&nbsp;
          <TitleKeyword hoverReady={hoverReady}>functional</TitleKeyword>
          &nbsp;and&nbsp;
          <TitleKeyword hoverReady={hoverReady}>responsible</TitleKeyword>
        </TitleLine>
        <TitleLine>websites and brand identities</TitleLine>
      </motion.div>
    </div>
  )
}

export const HomeHeroTitleMobile: React.FunctionComponent<HomeHeroTitleProps> = ({ hoverReady }) => {
  return (
    <div className='o-hero-title-wrapper -responsive'>
      <motion.div
        variants={slideTitleParent}
        key='title-line-parent'
        initial='initial'
        animate='animate'
        exit='exit'
        className='o-hero-title -home'
      >
        <TitleLine>We create digital </TitleLine>
        <TitleLine>experiences that are</TitleLine>

        <TitleLine>
          <TitleKeyword hoverReady={hoverReady}>original</TitleKeyword>,&nbsp;
          <TitleKeyword hoverReady={hoverReady}>functional</TitleKeyword>
        </TitleLine>
        <TitleLine>
          and&nbsp;
          <TitleKeyword hoverReady={hoverReady}>responsible</TitleKeyword>
        </TitleLine>
      </motion.div>
    </div>
  )
}

export const TitleLine: React.FunctionComponent<TitleLineProps> = ({ children }) => {
  return (
    <div className='u-overflow-reveal'>
      <motion.h3 variants={slideTitleChild} className={`o-hero-title__text`}>
        {children}
      </motion.h3>
    </div>
  )
}

export const TitleKeyword: React.FunctionComponent<TitleKeywordProps> = ({ children, hoverReady }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <span
      role='button'
      tabIndex={0}
      onMouseEnter={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (hoverReady && $desktop) {
          e.currentTarget.style.color = "rgba(250, 250, 250, 1)"
          titleHoverIn()
          setCursorState?.("is-link")
        }
      }}
      onMouseLeave={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (hoverReady && $desktop) {
          e.currentTarget.style.color = "inherit"
          titleHoverOut()
          setCursorState?.("")
        }
      }}
      onTouchStart={(e: React.TouchEvent<HTMLSpanElement>) => {
        if (hoverReady && !$desktop) {
          e.currentTarget.style.color = "rgba(250, 250, 250, 1)"
          titleHoverIn()
        }
      }}
      onTouchEnd={(e: React.TouchEvent<HTMLSpanElement>) => {
        if (hoverReady && !$desktop) {
          e.currentTarget.style.color = "inherit"
          titleHoverOut()
        }
      }}
      onTouchCancel={(e: React.TouchEvent<HTMLSpanElement>) => {
        if (hoverReady && !$desktop) {
          e.currentTarget.style.color = "inherit"
          titleHoverOut()
        }
      }}
    >
      {children}
    </span>
  )
}

import * as React from "react"
import { useContext } from "react"

// Componenets
import CursorContext from "../../context/cursorContext"
import NewsletterContext from "../../context/newsletterContext"

// Animations
import { $desktop } from "../../utils/breakpoints"
import { motion } from "framer-motion"
import { homeHeroFadeVars } from "../../helpers/homeHeroHelpers"
import { FaDribbble, FaInstagram, FaPaperPlane } from "react-icons/fa"

const HomeHeroSocialIcons: React.FunctionComponent = () => {
  // Context

  const { newsletterState, setNewsletterState } = useContext(NewsletterContext)
  const { setCursorState } = useContext(CursorContext)

  return $desktop ? (
    <motion.div className='c-home-hero__social-icons' initial='initial' animate='animate' exit='exit' variants={homeHeroFadeVars}>
      <a
        href='https://www.dribbble.com/silkstudio'
        className='c-home-hero-social-icons__icon'
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
      >
        <FaDribbble />
        {/* <p className='o-par-sml'>DB</p> */}
      </a>
      <a
        href='https://www.instagram.com/madebysilkstudio'
        className='c-home-hero-social-icons__icon'
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
      >
        <FaInstagram />
        {/* <p className='o-par-sml'>IN</p> */}
      </a>
      <div
        className='c-home-hero-social-icons__icon'
        onClick={() => setNewsletterState?.(!newsletterState)}
        role='button'
        tabIndex={0}
        onKeyPress={() => null}
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
      >
        <FaPaperPlane id='newsletter_icon' />
        {/* <p className='o-par-sml'>NW</p> */}
      </div>
    </motion.div>
  ) : null
}

export default HomeHeroSocialIcons

import * as React from "react"
import { useEffect, useState } from "react"

// Styles
import "./_home-hero.scss"

// Components
import ScrollPrompt from "../ScrollPrompt"
import HomeHeroTitle from "./HeroTitle/HomeHeroTitle"
import HomeHeroSocialIcons from "./HomeHeroSocialIcons"
import video from "../../assets/videos/Hero-video--export.mp4"
import { Container, Section } from "../StyledComponents/layoutComponentStyles"

// Animation
import { motion } from "framer-motion"

// Helpers
import { homeHeroFadeVars, homeHeroverticalTagline } from "../../helpers/homeHeroHelpers"

// Interfaces
interface HomeHeroProps {}

/*









*/

const HomeHero: React.FunctionComponent<HomeHeroProps> = () => {
  // States
  const [hoverReady, setHoverReady] = useState(false)
  // const [initialActive, setInitialActive] = useState(false)

  // Effects
  useEffect(() => {
    let timer: any

    if (window.sessionStorage.getItem("newSession") === null) {
      // setInitialActive(true)
      timer = setTimeout(() => {
        setHoverReady(true)
      }, 1250)
    } else {
      timer = setTimeout(() => {
        setHoverReady(true)
      }, 1250)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <video src={video} autoPlay muted loop playsInline className='c-home-hero__bg-video' itemType='video/mp4' />

      <Section fullHeight alignCenter>
        <Container sixteenClm fullHeight>
          <HomeHeroTitle hoverReady={hoverReady} />

          <div className='o-vertical-tagline' id='home_hero_vertical_tagline'>
            <motion.span initial='initial' animate='animate' exit='exit' variants={homeHeroverticalTagline}>
              We are Silk Studio.
            </motion.span>
          </div>
          <div>
            <ScrollPrompt variants={homeHeroFadeVars} />
          </div>
          <HomeHeroSocialIcons />
        </Container>
      </Section>
    </>
  )
}

export default HomeHero

/*










// import HomeHeroTitleInitial from "./HeroTitle/HomeHeroTitleInitial"
// import SapButtonSafeContext from "../../context/sapButtonSafeContext"
// import { $desktop } from "../../utils/breakpoints"
// import { useContext} from "react"


  const { sapButtonSafe, setSapButtonSafe } = useContext(SapButtonSafeContext)


    setSapButtonSafe?.(false)

    const io = new IntersectionObserver(entries => {
      for (const entry of entries) {
        console.log(`${entry.target.id} is in view: ${entry.isIntersecting}`)

        entry.isIntersecting ? setSapButtonSafe?.(false) : setSapButtonSafe?.(true)
      }
    })

    const target = document.getElementById("home_hero_vertical_tagline")

    if (target) {
      io.observe(target)
      console.log("observer now observing")
    }

    return () => {
      io.disconnect()
      console.log("observer now disconnected")
      setSapButtonSafe?.(true)
    }
    */
